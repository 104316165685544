<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>My wishlist</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ My wishlist
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
<!--            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            </div>-->
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                  <tr class="px-3">
                  <th>Book detail</th>
                  <th>Added date</th>
                  <th>Status</th>
                 <th class="pr-3"></th>
                </tr>
                </thead>
                <tbody v-if="wishlists.length > 0">
                <tr v-for="(wishlist, index)  of wishlists" :key="index">
                  <td>
                    <div class="text-secondary">
                      <span class="font-weight-black">Title:</span> {{ wishlist.book_title }}
                    </div>
                    <div class="text-secondary">
                      <span class="font-weight-black">Author:</span> {{ wishlist.book_author }}
                    </div>
                    <div class="text-secondary">
                      <span class="font-weight-black">Publisher:</span> {{ wishlist.book_publisher }}
                    </div>
                    <div class="text-secondary">
                      <span class="font-weight-black">Publication:</span> {{ wishlist.book_publication }}
                    </div>
                  </td>
                  <td>
                    <span class="font-weight-medium">
                      {{wishlist.added_on}}
                    </span>
                  </td>

                  <td>

                    <div class="text-secondary">
                      <span class="badge badge-success" v-if="wishlist.book_in_stock">Available</span>
                      <span class="badge badge-danger" v-else>Not available</span>
                    </div>
                    <div class="mt-4" v-if="wishlist.book_availability_notification">
                       <span class="badge badge-info">
                           Book reminder enabled
                       </span>

                    </div>
                  </td>
                  <td class="pr-0 text-center">
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span></slot>
                      </template>

                      <div class="navi navi-hover ">

                        <b-dropdown-text tag="div" class="navi-item">
                          <span href="#" class="navi-link" @click="deleteWishlist(wishlist.id)">
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text">  Delete</span>
                          </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="!wishlist.book_in_stock && !wishlist.book_availability_notification">
                              <span href="#" class="navi-link" @click="notifyBookAvailable(wishlist.id,wishlist.book_availability_notification)">
                                  <span class="navi-icon">
                                    <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="navi-text">Enable book available reminder</span>
                              </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="!wishlist.book_in_stock && wishlist.book_availability_notification">
                              <span href="#" class="navi-link" @click="notifyBookAvailable(wishlist.id,wishlist.book_availability_notification)">
                                  <span class="navi-icon">
                                    <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="navi-text">Disable book available reminder</span>
                              </span>
                        </b-dropdown-text>
                      </div>

                    </b-dropdown>

                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="50" class="text-center">
                    No wishlist found
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </v-app>
</template>

<script>
import WishlistService from "@/core/services/library/wishlist/WishlistService";

const wishlistService = new WishlistService();
export default {
  name: "Index",
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
  }, data() {
    return {
      wishlists: [],
      isLoading: true,
    }
  },
  mounted() {
    this.getUserWishlist()
  },
  methods: {
    getUserWishlist() {
      this.isLoading = true
      wishlistService.userWishlist(this.user.id).then(response => {
        this.wishlists = response.data.wishlist;
      }).finally(() => {
        this.isLoading = false
      })
    },
    deleteWishlist(id) {
      this.$confirm({
        message: `Are you sure you want to remove this book from your wishlist?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            wishlistService.delete(id).then(response => {
              this.$snotify.success("Wishlist deleted successfully")
              this.getUserWishlist();
            })
          }
        }
      });
    },
    notifyBookAvailable(wishlistId,book_availability_notification){
      let message=null;
      if(book_availability_notification){

         message='Are you sure you want to disabled a notification when this book becomes available?'
      }else{
          message='Are you sure you want to receive a notification when this book becomes available?'
      }
      this.$confirm({

        message:message,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {

            wishlistService
                .notifyBookAvailability(wishlistId)
                .then((response) => {
                  this.$snotify.success("Book availability notification has been enabled.");
                  this.getUserWishlist();
                })
                .catch((err) => {
                  this.$snotify.error(
                      "Something went wrong. Please try again later!!"
                  );
                })
                .finally(() => {

                })
          }
        },
      });
    }
  }
}
</script>

<style scoped>

</style>
